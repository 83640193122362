import { globalTheme } from "srs.sharedcomponents/lib/esm/styles/config";

const isuzuTheme = "#E12318";
export const customTheme = {
  colors: {
    ...globalTheme.colors,
    primary: isuzuTheme,
    continueShoppingButton: "#E12318",
    sideBarActiveBackGroundColor: "#E12318",
  },
};
